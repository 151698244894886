export const objectData = {
  company: {
    id: 0,
    name: '',
    comment: '',
    owned_number: null,
    separation_of_days: '00:00',
    plan: undefined,
    show_drive_monitor: 0,
    show_trajectory: 0,
    use_analyze: 0,
    use_announce: 0,
  },
  branch: {
    id: 0,
    company_id: 0,
    name: '',
    address: '',
    comment: '',
    alcohol_check_type: 0,
    alcohol_check_default_alcohol_detector: 0,
    alcohol_check_default_drinking: 0,
    alcohol_check_default_roll_call_type: 0,
  },
  user: {
    id: 0,
    company_id: 0,
    role: 0,
    name: '',
    email: '',
    tel: '',
    mobile_model: '',
    mobile_sim: '',
  },
  vehicle: {
    id: 0,
    branch_id: 0,
    name: '',
    manufacturer: null,
    model: null,
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
  },
  driveData: {
    id: 0,
    user_id: 0,
    vehicle_id: 0,
    user_name: '',
    vehicle_name: '',
    branch_name: '',
    compliance_rate: 0,
    date: '',
    distance: 0,
    driving_time: 0,
    regulation_num: 0,
    time_start: '',
    time_end: '',
    violation_count: 0,
    violations: [],
  },
  group: {
    id: 0,
    name: '',
    comment: '',
  },
  notification: {
    title: '',
    category: '',
    date: '',
    description: '',
    is_public: 0,
  },
}
