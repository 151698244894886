<template>
  <div>
    <h1 :class="$style.vehicleName">
      <router-link :to="{ name: 'branchShow', params: { id: vehicle.branch_id }}">
        {{ vehicle.branch_name }}
      </router-link>{{vehicle.name}}
    </h1>
    <div class="row">

      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <div class="d-inline-block">
                <a-dropdown v-if="!loading" :class="$style.operationBtn">
                  <span class="ant-dropdown-link">
                    <i class="icmn-cog mr-2"/>
                    <span class="hidden-lg-down mr-2">操作</span>
                    <a-icon type="down"/>
                  </span>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <router-link :to="{ name: 'vehicleUpdate', params: { id: vehicle.id }}">
                        <i class="icmn-pencil mr-2"/>
                        <span>編集</span>
                      </router-link>
                    </a-menu-item>
                    <a-menu-item @click="showModal">
                      <i class="icmn-bin mr-2"/>
                      <span>削除</span>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <a-modal
                  :title="title"
                  :visible="visible"
                  :confirm-loading="confirmLoading"
                  okText='削除'
                  okType='danger'
                  cancelText='キャンセル'
                  @ok="handleOk"
                  @cancel="handleCancel"
                />
              </div>
            </div>
            <h2>基本情報</h2>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <ul v-if="!loading" :class="$style.basicInfoList">
              <li><span :class="$style.infoLabel">車両名:</span><span :class="$style.infoValue">{{vehicle.name}}</span></li>
              <li><span :class="$style.infoLabel">車両番号:</span><span :class="$style.infoValue">{{vehicle.number}}</span></li>
              <li><span :class="$style.infoLabel">車種:</span><span :class="$style.infoValue">{{carType}}</span></li>
              <li><span :class="$style.infoLabel">メーカー:</span><span :class="$style.infoValue">{{vehicle.manufacturer}}</span></li>
              <li><span :class="$style.infoLabel">型式:</span><span :class="$style.infoValue">{{vehicle.model}}</span></li>
              <li><span :class="$style.infoLabel">リース満了日:</span><span :class="$style.infoValue">{{vehicle.lease_expiration_date | date}}</span></li>
              <li><span :class="$style.infoLabel">車検満了日:</span><span :class="$style.infoValue">{{vehicle.inspection_expiration_date | date}}</span></li>
              <li><span :class="$style.infoLabel">次回法定点検日:</span><span :class="$style.infoValue">{{vehicle.legal_inspection_date | date}}</span></li>
              <li><span :class="$style.infoLabel">任意保険満了日:</span><span :class="$style.infoValue">{{vehicle.insurance_expiration_date | date}}</span></li>
              <li><span :class="$style.infoLabel">作成日:</span><span :class="$style.infoValue">{{vehicle.created_at | moment}}</span></li>
              <li><span :class="$style.infoLabel">更新日:</span><span :class="$style.infoValue">{{vehicle.updated_at | moment}}</span></li>
              <li><span :class="$style.infoLabel">備考:</span><span :class="$style.infoValue">{{vehicle.description}}</span></li>
              <li>{{vehicle.insurance_expiration_date}}</li>
            </ul>
          </div>
        </section>
      </div>

      <div class="col-lg-6">
      </div>
    </div>
    <drive-data-list
      target="vehicle"
      :targetId="vehicleId"
      :key="$route.fullPath + '_BranchDriveDataView'"
    />
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import DriveDataList from '@/views/drive_data/list'
import { objectData } from '@/services/object'
import { enumData } from '@/services/enum'

export default {
  components: {
    DriveDataList,
  },
  data() {
    return {
      loading: true,
      vehicleId: this.$route.params.id,
      vehicle: Object.assign({}, objectData.vehicle),
      visible: false,
      carType: '',
      title: '',
      confirmLoading: false,
    }
  },
  watch: {
    vehicle: function(val) {
      if (val.car_type !== 0) {
        this.carType = enumData.carType.find(item => item.value === val.car_type).label
      }
    },
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'vehicles/' + this.$route.params.id)
    result.then(response => {
      this.loading = false
      this.vehicle = response
      console.log(response)
    })
  },
  methods: {
    showModal(index) {
      console.log('show Modal')
      this.title = '本当に ' + this.vehicle.name + ' を削除しますか？'
      this.visible = true
    },
    handleOk() {
      console.log('handle ok')
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('delete', 'vehicles/' + this.vehicle.id)
      result.then(response => {
        this.$notification['success']({
          message: this.vehicle.name + ' を削除しました',
        })
        this.$router.push('/branches/' + this.vehicle.branch_id)
        this.confirmLoading = false
        this.visible = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 削除に失敗しました。',
          })
          this.loading = false
        })
    },
    handleCancel() {
      this.visible = false
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format('YYYY/MM/DD HH:mm')
      return ''
    },
    date: function (date) {
      if (date) return moment(date).format('YYYY/MM/DD')
      return ''
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
